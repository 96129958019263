<template>
  <div class="animated fadeIn">
<!--
    <b-button variant="danger" :active="true" @click="Monthly()">Monthly</b-button>
    <b-button variant="primary" :active="true" @click="Weekly()">Weekly</b-button>
    <b-button variant="success" :active="true" @click="Daily()">Daily</b-button>
-->
    <b-card :title="$t('message.title.allIncome')">
    <div>
      {{ $t('message.machineId') }} : (options)
      <multi-list-select 
        :list="machineData"
        option-value="machineId"
        option-text="name"
        :selected-items="selectedMachine"
        placeholder="All"
        @select="onSelectMachine">
      </multi-list-select>
    </div><br>
    <div>
      {{ $t('message.product') }} : (options)
      <multi-list-select 
        :list="ProductItems"
        option-value="id"
        option-text="name"
        :selected-items="selectedProduct"
        placeholder="All"
        @select="onSelect">
      </multi-list-select>
    </div><br>
    <div class="row">
      <div class="col-sm-6 col-md-3">
        {{ $t('message.date') }} : <br>
        <date-picker 
          range
          type="datetime" 
          v-model="date" 
          lang="en" 
          format="yyyy-MM-dd" 
          width=220>
        </date-picker>
      </div>
      <div class="col-sm-6 col-md-3">
        <br><b-button v-on:click="searchFn()" variant="primary"><i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}</b-button>&nbsp;&nbsp;&nbsp;
        <b-button v-on:click="ExportFn(rowData.details)" variant="success"><i class="icon-docs"></i>&nbsp;{{ $t('message.export') }}</b-button>
      </div> 
    </div><br>
    <div class="wrapper">
      <div class="row">
        <div class="col-12">
          <br><b-list-group-item class="table-responsive">
            <table class="table2" v-if="rowData != ''"> 
              <thead>
                <tr><th colspan="2">{{ $t('message.machineId') }} {{rowData.machineId}}</th></tr>
                <tr>
<!--                  <th>หมายเลขตู้</th>-->
                  <th>{{ $t('message.productId') }} <i class="fa fa-sort-amount-asc" @click="sortFunction('id')"></i></th>
                  <th>{{ $t('message.productName') }}</th>
                  <th style="text-align: right"><i class="fa fa-sort-amount-desc" @click="sortFunction('qty')"></i> {{ $t('message.amount') }}</th>
                  <th style="text-align: right"><i class="fa fa-sort-amount-desc" @click="sortFunction('price')"></i> {{ $t('message.sale') }}</th>
                  <th style="text-align: right"><i class="fa fa-sort-amount-desc" @click="sortFunction('price')"></i> {{ $t('message.acceptorMoney') }}</th>
                </tr>
              </thead>
              <template v-for="item in rowData.details">
                <tr v-if="item.product">
<!--                  <td>{{item.machineId}}</td>-->
                  <td>{{item.productId}}</td>
                  <td>{{item.product.name}}</td>
                  <td style="text-align: right">{{webServices.addCommas(item.qty)}}</td>
                  <td style="text-align: right">{{webServices.addCommas(item.price)}}</td>
                  <td style="text-align: right">{{webServices.addCommas(item.acceptorMoney)}}</td>
               </tr>
              </template>
              <tfoot>
                <tr>
                  <th></th>
                  <th>{{ $t('message.sum') }}</th>
                  <th style="text-align: right">{{webServices.addCommas(totalQty)}}</th>
                  <th style="text-align: right">{{webServices.addCommas(totalAmount)}}</th>
                  <th style="text-align: right">{{webServices.addCommas(totalAcceptorMoney)}}</th>
                </tr>
              </tfoot>
            </table>
            <template v-else><p style="text-align: center">{{ $t('message.transactionNotFound') }}</p></template>
<!--            <uib-pagination :total-items="totalRows" :items-per-page="20" v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>-->
          </b-list-group-item>
        </div>
      </div>
    </div>
    </b-card>
    <b-modal ref="exportModal" :title="$t('message.modalTitle.summary')" @hidden="closeExportModal()">
      <table class="table table no-border table-responsive table-sm">
        <tr>
          <td>{{ $t('message.date') }}</td>
          <td>{{this.searchData.start}} - {{this.searchData.end}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.machineId') }}</td>
          <td>{{rowData.machineId}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.amount') }}</td>
          <td>{{totalQty}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.sale') }}</td>
          <td>{{totalAmount}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.insert') }}</td>
          <td>{{totalAcceptorMoney}}</td>
        </tr>
      </table>
      <div slot="modal-footer" class="w-100">
        <download-excel
          class   = "btn btn-success float-right"
          :title = "title"
          :data   = "rowData.details"
          :fields = "exportField"
          :name    = "reportFilename">
        Export
        <i class="icon-docs"></i>
        </download-excel>
<!--        <b-btn variant="danger" class="float-left" @click="closeExportModal()">Close</b-btn>  -->
      </div>
    </b-modal>
  </div>
</template>
<script>
import webServices from '../../script'
import DatePicker from '../../custom_modules/vue2-datepicker'
import moment from 'moment'
import { ModelListSelect, MultiListSelect } from '../../custom_modules/search-select'
export default {
  name: 'SalesByUser',
  components: {
    DatePicker,
    ModelListSelect,
    MultiListSelect
  },
  data () {
    return {
      webServices: webServices,
      totalRows: 1,
      pagination: {},
      searchData: {
        productId: '',
        machineId: ''
      },
      exportField: {
        รหัสสินค้า: 'productId',
        sku: 'product.sku',
        ชื่อสินค้า: 'product.name',
        จำนวน: 'qty',
        ยอดขาย: 'price'
      },
      date: '',
      rowData: {
        details: []
      },
      totalQty: '',
      totalAmount: '',
      totalAcceptorMoney: '',
      ProductItems: [],
      selectedProduct: [],
      selectedMachine: [],
      machineData: [],
      exportData: [],
      title: [],
      reportFilename: ''
    }
  },
  methods: {
    getMachine () {
      this.$Progress.start()
      webServices.getMachineList(window.localStorage.getItem('userId')).then((res) => {
        this.$Progress.finish()
        this.machineData = res.data
        // console.log(res.data)
      }).catch((err) => {
        this.$Progress.fail()
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
        this.$toast.error({
          title: 'ERROR',
          message: {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
        })
        console.log('error @machine')
        console.log(err)
      })
    },
    searchFn () {
      this.$Progress.start()
      if (this.date) {
        // console.log(this.date)
        var start = this.date[0]
        var end = this.date[1]
        var timeEnd = this.date[1].toLocaleTimeString()
        if (timeEnd === '00:00:00') {
          end.setHours(23, 59, 59, 999)
        }
        this.searchData.start = moment(start).format('YYYY-MM-DD HH:mm:ss')
        this.searchData.end = moment(end).format('YYYY-MM-DD HH:mm:ss')
      }
      // console.log(this.searchData)
      webServices.getReportByUser(this.searchData).then((res) => {
        this.$Progress.finish()  
        console.log(res.data)
        this.rowData = res.data
        this.totalAmount = res.data.totalAmount
        this.totalQty = res.data.totalQty
        this.totalAcceptorMoney = res.data.totalAcceptorMoney
        this.title[0] = `หมายเลขตู้ ${this.rowData.machineId}`
        this.title[1] = `วันที่ ${this.searchData.start} ถึง ${this.searchData.end}`
        this.reportFilename = `Report_${this.rowData.machineId}_${moment(this.searchData.start).format('YYYY-MM-DD')}_to_${moment(this.searchData.end).format('YYYY-MM-DD')}.xls`
      }).catch((err) => {
        this.$Progress.fail()
        if (err.response.status === 401) {
          webServices.tokenExpire()
        }
        const errorText = {
          error: err.response.data,
          code: err.response.status,
          text: err.response.statusText
        }
        this.$toast.error({
          title: 'ERROR',
          message: JSON.stringify(errorText)
        })
        console.log('error @report by user')
        console.log(err)
      })
    },
    getProduct () {
      webServices.getProductList('').then((res) => {
        // console.log(res.data)
        this.ProductItems = res.data
      }).catch((err) => {
        const errorText = {
          error: err.response.data,
          code: err.response.status,
          text: err.response.statusText
        }
        this.$toast.error({
          title: 'ERROR',
          message: JSON.stringify(errorText)
        })
        console.log('error @get product ORDER')
        console.log(err)
      })
    },
    onSelect (items, lastSelectItem) {
      // console.log(items)
      this.selectedProduct = items
      this.searchData.productId = this.selectedProduct.map(data => data.id)
    },
    onSelectMachine (items, lastSelectItem) {
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
    },
    sortFunction (type) {
      if (type === 'qty') {
        this.rowData.details.sort(function (a, b) { return (a.qty < b.qty) ? 1 : ((b.qty < a.qty) ? -1 : 0) })
      } else if (type === 'price') {
        this.rowData.details.sort(function (a, b) { return (Number(a.price) < Number(b.price)) ? 1 : ((Number(b.price) < Number(a.price)) ? -1 : 0) })
      } else if (type === 'id') {
        this.rowData.details.sort(function (a, b) { return (a.productId > b.productId) ? 1 : ((b.productId > a.productId) ? -1 : 0) })
      }
    },
    ExportFn () {
      if (this.rowData.details.length > 0) {
        console.log('push sum')
        // this.exportData = data
        this.rowData.details.push({
          productId: '',
          product: {
            sku: '',
            name: 'สรุป'
          },
          qty: this.totalQty,
          price: this.totalAmount,
          acceptorMoney: this.totalAcceptorMoney
        })
      }
      this.$refs.exportModal.show()
    },
    closeExportModal () {
      console.log('close')
      this.rowData.details.pop()
      this.$refs.exportModal.hide()
    }
  },
  mounted () {
    this.getProduct()
    this.getMachine()
  }
}
</script>

<style>
  @import './styleRe.css'
</style>